'use client'

import { useState } from 'react'
import FollowList from '@/components/FollowList'
import Tabs from '@/components/Tabs'
import {
  Hub,
  HubCollaborator,
  ModalType,
  SupplementalTabContent,
} from '@/lib/types'
import { HeaderTotals } from '@/lib/types'
import HubCollaborators from './HubCollaborators'
import HubDetails from './HubDetails'
import HubInfoTabsSkeleton from './HubInfoTabsSkeleton'

type HubInfoTabsProps = {
  hub: Hub
  collaborators: HubCollaborator[]
}

export default function HubInfoTabs(props: HubInfoTabsProps) {
  const { hub } = props

  const [headerTotals, setHeaderTotals] = useState<HeaderTotals>({
    collaborators: undefined,
    hubFollowers: undefined,
  })

  if (!hub) return <HubInfoTabsSkeleton />

  const contentHeaderTotals: { [key: string]: number | undefined } = {}
  contentHeaderTotals['collaborators'] = headerTotals.collaborators
  contentHeaderTotals['followers'] = headerTotals.hubFollowers

  const details = (
    <>
      <HubDetails hub={hub} />
    </>
  )

  const collaborators = (
    <>
      <HubCollaborators hub={hub} limit={8} setHeaderTotals={setHeaderTotals} />
    </>
  )

  const followers = (
    <>
      <FollowList
        type="hubFollowers"
        handleOrPublicKey={hub.handle}
        limit={8}
        setHeaderTotals={setHeaderTotals}
      />
    </>
  )

  const content = {
    details,
    collaborators,
    followers,
  }

  const supplementalContent: SupplementalTabContent = {}

  if (headerTotals.collaborators && headerTotals.collaborators > 8) {
    const viewAllCollaborators = (
      <div className="md:pb-28">
        <HubCollaborators hub={hub} limit={1000} />
      </div>
    )

    supplementalContent.collaborators = {
      content: viewAllCollaborators,
      label: `View all (${headerTotals.collaborators})`,
      modalHeader: 'Collaborators',
      searchInput: false,
      searchInputPlaceholder: 'Search users',
      modalType: ModalType.ViewAll,
    }
  }

  if (headerTotals.hubFollowers && headerTotals.hubFollowers > 8) {
    const viewAllFollowers = (
      <div className="md:pb-28">
        <FollowList
          type="hubFollowers"
          handleOrPublicKey={hub.handle}
          limit={1000}
        />
      </div>
    )

    supplementalContent.followers = {
      content: viewAllFollowers,
      label: `View all (${headerTotals.hubFollowers})`,
      modalHeader: 'Followers',
      searchInput: false,
      searchInputPlaceholder: 'Search users',
      modalType: ModalType.ViewAll,
    }
  }

  return (
    <div className="tabMarginTop">
      <Tabs
        content={content}
        supplementalContent={supplementalContent}
        contentHeaderTotals={contentHeaderTotals}
      />
    </div>
  )
}
