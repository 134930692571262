'use client'

import { useContext } from 'react'
import Ellipsis from '@/components/tokens/Ellipsis'
import Audio from '@/contexts/AudioContext'
import { Hub, PlayType, Release } from '@/lib/types'

export default function HubHeaderListBox(props: {
  hub: Hub
  releases: Release[]
}) {
  const { releases } = props
  const { addMultipleReleasesToQueue } = useContext(Audio.Context)

  const options = {
    playNext: {
      label: 'Play next',
      type: 'playNext',
      callback: (releases: Release[]) => {
        addMultipleReleasesToQueue(releases, PlayType.Next)
      },
    },
    playLater: {
      label: 'Play later',
      type: 'playLater',
      callback: (releases: Release[]) => {
        addMultipleReleasesToQueue(releases, PlayType.Later)
      },
    },
  }

  return <Ellipsis options={options} value={releases} variant={'hubHeader'} />
}
