'use client'

import React, { useContext, useState } from 'react'
import { z } from 'zod'
import Form from '@/components/Form'
import Button from '@/components/tokens/Button'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { addColloboratorToHub } from '@/lib/hub/addCollaboratorToHub'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  FormField,
  Hub,
  HubCollaborator,
} from '@/lib/types'
import { validateFormSchema } from '@/lib/utils/validateFormSchema'

type HubAddCollaboratorFormProps = {
  hub: Hub
  collaborators: HubCollaborator[]
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  toggleExpand: () => void
}

type HubCollaboratorData = {
  hubPublicKey: string
  collaboratorPublicKey: string
  canAddContent: boolean
  canAddCollaborators: boolean
  allowance: number
  [key: string]: string | boolean | number
}

export default function HubAddCollaboratorForm(
  props: HubAddCollaboratorFormProps,
) {
  const { hub, collaborators, expanded, setExpanded, toggleExpand } = props

  const addHubCollaboratorFields: FormField[] = [
    {
      name: 'collaboratorPublicKey',
      label: 'Collaborator Wallet Address',
      type: 'text',
      hint: 'Enter the wallet address of your collaborator',
      value: undefined,
      required: true,
    },
    {
      name: 'canAddContent',
      label: 'Can add content',
      type: 'checkbox',
      required: false,
      value: false,
    },
    {
      name: 'canAddCollaborators',
      label: 'Can add collaborators',
      type: 'checkbox',
      required: false,
      value: false,
    },
    {
      name: 'allowanceType',
      label: '',
      type: 'conditional',
      value: 'unlimited',
      required: false,
      inputProps: {
        type: 'radio',
        dependentOn: 'canAddContent',
        additionalDependency: 'canAddCollaborators',
        dependencyTrigger: true,
        inputSelectOptions: [
          { label: 'Unlimited amount', value: 'unlimited' },
          { label: 'Limited amount', value: 'limited' },
        ],
      },
    },
    {
      name: 'allowance',
      label: 'Allowance amount',
      type: 'conditional',
      placeholder: 'Enter an amount',
      hint: 'Amount determines the number of actions a Collaborator can execute on the Hub (i.e. publishing Releases through the Hub, reposting to the Hub and adding Collaborators to the Hub). Selecting ‘Unlimited’ will allow them unlimited actions. This can be updated at any time.',
      required: true,
      value: '0',
      inputProps: {
        type: 'number',
        dependentOn: 'allowanceType',
        dependencyTrigger: 'limited',
      },
    },
  ]

  const [fields, setFields] = useState<FormField[]>(addHubCollaboratorFields)
  const [submitting, setSubmitting] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const { addToast } = useContext(Toast.Context)
  const { wallet } = useContext(Wallet.Context)
  const { update } = useContext(Update.Context)

  const validationSchema = z.object({
    collaboratorPublicKey: z
      .string()
      .min(1, { message: 'Collaborator Address is required' }),
  })

  const formatData = () => {
    const data: HubCollaboratorData = {
      hubPublicKey: hub.publicKey,
      collaboratorPublicKey: '',
      canAddContent: false,
      canAddCollaborators: false,
      allowance: 0,
    }

    fields.forEach((field: FormField) => {
      data[field.name] = field.value

      if (field.name === 'allowance') {
        data['allowance'] = parseInt(field.value)
      }

      if (field.name === 'collaboratorPublicKey') {
        data['collaboratorPublicKey'] = field.value.replace(/\s/g, '')
      }
    })

    return data
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const isCollaborator = collaborators.some(
      (collaborator) => collaborator.publicKey === fields[0].value,
    )

    if (isCollaborator) {
      addToast({
        message: 'Collaborator already added to hub',
        variant: 'error',
      })

      return
    }

    const validationResult = validateFormSchema(
      validationSchema,
      fields,
      setFields,
    )

    if (!validationResult.success) return false

    setSubmitting(true)
    try {
      addToast({
        message: 'Adding collaborator to hub',
        variant: 'loading',
      })
      const data = formatData()
      const { error } = await addColloboratorToHub({ ...data, wallet })

      if (error) {
        addToast({
          message: 'Hub collaborator could not be added',
          variant: 'error',
        })
      } else {
        addToast({
          message: 'Collaborator added successfully',
          variant: 'success',
        })
        update()
        setExpanded(false)
        setFields(addHubCollaboratorFields)
      }
    } catch (error) {
      addToast({
        message: 'Hub collaborator could not be added',
        variant: 'error',
      })
    }
    setSubmitting(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    setFields((fields) => {
      const updatedFields = fields.map((field) => {
        const allowance = fields.find((field) => field.name === `allowance`)

        const allowanceType = fields.find(
          (field) => field.name === `allowanceType`,
        )

        if (field.name === `allowanceType`) {
          // type checking for allowance

          if (allowance) {
            if (value === 'limited') {
              // if the user selects 'limited', set the allowance to an empty string

              if (allowance.value === '-1' || allowance.value === undefined) {
                allowance.value = ''
              }
            }
            // if the user selects 'unlimited', set the allowance to -1

            if (value === 'unlimited') {
              allowance.value = '-1'
            }
          }
        }
        // set the allowance to -1 if the user selects 'unlimited' for canAddContent or canAddCollaborators

        if (
          field.name === 'canAddContent' ||
          field.name === 'canAddCollaborators'
        ) {
          if (
            field.value === true &&
            allowance &&
            allowanceType?.value === 'unlimited'
          ) {
            allowance.value = '-1'
          }
        }

        if (field.name === name) {
          return {
            ...field,
            value: value,
          }
        }

        return field
      })

      const validationResult = validateFormSchema(
        validationSchema,
        updatedFields,
        setFields,
      )

      if (validationResult.success) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }

      return updatedFields
    })
  }

  return (
    <div className="w-full">
      {!expanded && (
        <div>
          <Button
            label={'+ Add Collaborator'}
            variant={ButtonVariant.Large}
            color={ButtonColor.Grey}
            font={ButtonFont.Body1}
            textColor={ButtonColor.Grey}
            responsiveness={ButtonResponsiveness.Full}
            onClick={() => toggleExpand()}
          />
        </div>
      )}
      {expanded && (
        <div className="mb-20">
          <Form
            fields={fields}
            setFields={setFields}
            buttonLabel="Add Collaborator"
            handleChange={handleChange}
            handleSubmit={(e) => handleSubmit(e)}
            disableSubmit={submitting}
            submittingLabel={'Adding Collaborator'}
            allowCancel={true}
            handleCancel={() => toggleExpand()}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </div>
  )
}
