import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { PaginationProps } from '../types'

export const fetchCollaboratorsForHub = cache(
  async (
    publicKey: string,
    pagination = { limit: 20, offset: 0, sort: 'desc' } as PaginationProps,
    withAccountData = true,
  ) => {
    await initSdk()

    const collaboratorsResponse = await Nina.Hub.fetchCollaborators(
      publicKey,
      pagination,
      withAccountData,
    )

    return collaboratorsResponse
  },
)
