'use client'

import { parseAsString, useQueryState } from 'next-usequerystate'
import { useContext, useMemo, useRef } from 'react'
import ContentPage from '@/components/ContentPage'
import ContentPageSkeleton from '@/components/ContentPageSkeleton'
import TabsPaginated from '@/components/TabsPaginated'
import Wallet from '@/contexts/WalletContext'
import { FilterType, Hub, TabbedPageURLSearchParams, View } from '@/lib/types'
import { detectMobile } from '@/lib/utils/useScreenWidth'

type HubContentTabsProps = {
  hub: Hub
  searchParams: TabbedPageURLSearchParams
}

export default function HubContentTabs({
  hub,
  searchParams,
}: HubContentTabsProps) {
  const { wallet } = useContext(Wallet.Context)
  const scrollToRef = useRef<null | HTMLDivElement>(null)
  const isMobile = detectMobile()

  const [view, setView] = useQueryState<string>(
    'view',
    parseAsString.withDefault(isMobile ? 'list' : searchParams.view),
  )

  const isHubAuthority = useMemo(
    () => wallet?.publicKey?.toBase58() === hub.authority,
    [wallet.publicKey?.toBase58()],
  )

  if (!hub) return <ContentPageSkeleton items={9} view={view as View} />

  const hubFilterOptions = [
    {
      label: FilterType.Sort,
      filterValues: [
        { label: 'Recently added', value: 'recentlyAdded' },
        { label: 'Title A-Z', value: 'titleAZ' },
        { label: 'Title Z-A', value: 'titleZA' },
        { label: 'Earliest', value: 'earliest' },
      ],
    },
    // {
    //   label: FilterType.Source,
    //   filterValues: [
    //     { label: 'Recently added', value: 'recentlyAdded' },
    //     { label: 'Title A-Z', value: 'titleAZ' },
    //     { label: 'Title Z-A', value: 'titleZA' },
    //   ],
    // },
  ]

  const all = (
    <ContentPage
      publicKey={hub.handle}
      type="hubAll"
      searchParams={searchParams}
      scrollToRef={scrollToRef}
      view={view as View}
      isHubAuthority={isHubAuthority}
      hubPublicKey={hub.publicKey}
    />
  )

  const content = {
    all,
  }

  return (
    <div className="innerContainerSpace">
      <TabsPaginated
        hideTabs={true}
        content={content}
        filterOptions={hubFilterOptions}
        searchParams={searchParams}
        scrollToRef={scrollToRef}
        view={view as View}
        setView={setView}
      />
    </div>
  )
}
