import { useContext, useRef, useState } from 'react'
import Form from '@/components/Form'
import FileService from '@/contexts/FileServiceContext'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import {
  FlowType,
  FormField,
  Hub,
  HubUpdateFormFieldData,
  ImageCropFile,
  ImageCropProps,
} from '@/lib/types'
import { convertFieldsToObject } from '@/lib/utils/convertFieldsToObject'
import getCroppedImage from '@/lib/utils/cropImage'
import { reusedConsts } from '@/lib/utils/reusedConsts'

type HubUpdateFormProps = {
  hub: Hub
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type FormFieldNoValue = Omit<FormField, 'value'>

type ImageFieldProps = FormFieldNoValue & {
  value: ImageCropProps
}
const IMAGE_SIZE_LIMIT = reusedConsts.IMAGE_SIZE_LIMIT

export default function HubUpdateForm(props: HubUpdateFormProps) {
  const { hub, setIsOpen } = props
  const { update } = useContext(Update.Context)
  const { addToast } = useContext(Toast.Context)
  const { updateHub } = useContext(FileService.Context)
  const [submitting, setSubmitting] = useState(false)
  const imageRef = useRef<HTMLInputElement>(null)

  const sanitizedHubDescription = () => {
    if (hub.data.description === undefined) {
      return hub.data.description
    } else if (hub.data.description === 'undefined') {
      return ''
    } else {
      return hub.data.description
    }
  }

  const hubUpdateFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      value: hub.data.displayName,
      required: true,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      value: sanitizedHubDescription(),
      required: false,
    },
    {
      name: 'image',
      label: 'Image',
      type: 'imageUpload',
      value: hub.data.image,
      required: true,
      inputProps: {
        fileSizeLimit: IMAGE_SIZE_LIMIT,
        inputRef: imageRef,
      },
    },
  ] as FormField[]

  const [fields, setFields] = useState(hubUpdateFields)

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement
    const { name, value } = target
    setFields((prevFields: FormField[]) => {
      const updatedFields = prevFields.map((field) => {
        if (field.name === name) {
          return {
            ...field,
            value,
          }
        }

        return field
      })

      return updatedFields
    })
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      const imageFile = await imageCropToFile(hub.publicKey)

      const data = convertFieldsToObject(
        fields,
        FlowType.Hub,
      ) as HubUpdateFormFieldData

      if (imageFile) {
        data.image = imageFile as ImageCropFile
      }

      if (data && hub.handle) {
        setSubmitting(true)

        const { hub: hubResponse, error } = await updateHub(hub.handle, data)

        if (error) {
          addToast({
            variant: 'error',
            message: error,
          })
          setSubmitting(false)

          return
        }

        if (hubResponse) {
          addToast({
            variant: 'success',
            message: `Hub ${hub.data.displayName} updated successfully`,
          })
          update()
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch {
      addToast({
        message: 'Hub update unsuccessful. Please try again.',
        variant: 'error',
      })
    }
    setSubmitting(false)
    setIsOpen(false)
  }

  const handleCroppedImage = (data: object) => {
    setFields((prevFields) => {
      const prevFieldsCopy = [...prevFields]

      const artworkField = prevFieldsCopy.find(
        (field) => field.name === 'image',
      )

      if (artworkField) {
        artworkField.value = data
      }

      return prevFieldsCopy
    })
  }

  const imageCropToFile = async (filename?: string) => {
    const imageField = fields?.find(
      (field: FormField) => field.name === 'image',
    ) as ImageFieldProps

    const { imageSrc, file, croppedAreaPixels, croppedImage } = imageField.value
    let imageFile = file

    if (!croppedImage && file && croppedAreaPixels) {
      imageFile = (await getCroppedImage(
        imageSrc as string,
        croppedAreaPixels,
        filename,
        file?.type,
      )) as ImageCropFile
    }

    return imageFile
  }

  const handleCancel = () => {
    setFields((prevFields: FormField[]) => {
      const updatedFields = prevFields.map((field) => {
        return { ...field, value: undefined }
      })

      return updatedFields
    })
    setIsOpen(false)
  }

  return (
    <>
      <Form
        fields={fields}
        setFields={setFields}
        buttonLabel="Update Hub"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleCroppedImage={handleCroppedImage}
        isInModal={true}
        allowCancel={true}
        handleCancel={handleCancel}
        inSettings={true}
        disableSubmit={submitting}
        submittingLabel="Updating Hub"
      />
    </>
  )
}
