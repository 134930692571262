'use client'

import { WalletContextState } from '@solana/wallet-adapter-react'
import axios from 'axios'
import clsx from 'clsx'
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import ImageWithArweaveFallbackClient from '@/components/ImageWithArweaveFallbackClient'
import Tabs from '@/components/Tabs'
import Ellipsis from '@/components/tokens/Ellipsis'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { fetchHub } from '@/lib/hub/fetchHub'
import { toggleHubContentVisibility } from '@/lib/hub/toggleHubContentVisibility'
import { Hub, HubCollaborator, ListBoxView, Release } from '@/lib/types'
import ImagePlaceholderBase64 from '@/lib/utils/ImagePlaceholderBase64'
import HubCollaboratorSettings from './components/HubCollaboratorSettings'
import HubCollaboratorSkeleton from './components/HubCollaboratorSkeleton'
import HubUpdateForm from './components/HubUpdateForm'

type HubSettingsTabsProps = {
  hub: Hub
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  collaborators: HubCollaborator[]
  isAuthority: boolean
  canAddCollaborator: boolean
  inSettings?: boolean
  releases: Release[] | undefined
}

type HiddenListItemProps = {
  release: Release
  hub: Hub
  wallet: WalletContextState
  update: () => void
  addToast: (toast: { message: string; variant: string }) => void
}

function HiddenListItem({
  release,
  wallet,
  update,
  addToast,
  hub,
}: HiddenListItemProps) {
  const hiddenReleaseOptions = {
    unhide: {
      label: 'Unhide',
      type: 'button',
      callback: async () => {
        await handleUnhide(hub)
      },
    },
  }

  const options = () => {
    return hiddenReleaseOptions
  }

  const handleUnhide = async (hub: Hub) => {
    try {
      addToast({
        message: 'Unhiding release from hub',
        variant: 'loading',
      })

      const result = await toggleHubContentVisibility({
        hubPublicKey: hub.publicKey,
        releasePublicKey: release.publicKey,
        wallet,
      })

      if (result?.error) {
        addToast({
          message: 'Error unhiding release from hub',
          variant: 'error',
        })
      } else {
        addToast({
          message: 'Release is now visible on hub',
          variant: 'success',
        })
        update()
      }
    } catch (error) {
      console.error(error)
      addToast({
        message: 'Error unhiding release from hub',
        variant: 'error',
      })
    }
  }

  return (
    <li className="body-1 group flex justify-between border-b-1 border-grey-10 py-12 last-of-type:border-none">
      <div className="flex pr-12 ">
        <ImageWithArweaveFallbackClient
          alt={release.metadata.name}
          src={release.metadata.image}
          width={40}
          height={40}
          className={clsx('imageBorder flex !h-40 !w-40')}
          placeholder="blur"
          blurDataURL={ImagePlaceholderBase64}
        />
      </div>
      <div className={clsx('flex-1')}>
        <div className={clsx('line-clamp-1 text-left text-black')}>
          {release.metadata.name}
        </div>
        <p className="ui-1-tabular mt-[2px] whitespace-nowrap text-grey-45">
          {new Date(release.datetime).toLocaleString()}
        </p>
      </div>
      <div className="flex max-w-[250px] items-start ">
        <Ellipsis
          options={options()}
          value={release}
          view={ListBoxView.List}
          parentIndex={0}
          align={'left'}
        />
      </div>
    </li>
  )
}

function HubSettingsTabs(props: HubSettingsTabsProps) {
  const { hub, setIsOpen, isAuthority, canAddCollaborator, collaborators } =
    props

  const AUTHORITY_PUBLIC_KEY = '11111111111111111111111111111111'
  const [fetched, setFetched] = useState(true)

  const [hubCollaborators, setHubCollaborators] =
    useState<HubCollaborator[]>(collaborators)

  const [hiddenReleases, setHiddenReleases] = useState<Release[]>([])
  const { date, update } = useContext(Update.Context)
  const { addToast } = useContext(Toast.Context)
  const { wallet } = useContext(Wallet.Context)
  useEffect(() => {
    const handleFetchCollaborators = async () => {
      setFetched(false)
      try {
        const { collaborators: fetchedCollaborators } = await fetchHub(
          hub.handle,
        )

        if (!fetchedCollaborators) {
          setFetched(true)

          return
        }

        const formattedCollaborators = fetchedCollaborators
          .filter(
            (collaborator) =>
              collaborator.accountData?.collaborator !== undefined,
          )
          .sort((a, b) => {
            const addedByA = a.accountData?.collaborator?.addedBy || ''
            const addedByB = b.accountData?.collaborator?.addedBy || ''

            // if collaborator was added by is "11111111111111111111111111111111", then they are authority and should be at the top
            if (addedByA === AUTHORITY_PUBLIC_KEY) return -1

            if (addedByB === AUTHORITY_PUBLIC_KEY) return 1

            return 0
          })

        setHubCollaborators(formattedCollaborators)
      } catch (error) {
        console.warn(error)
      }
      setFetched(true)
    }

    const handleFetchHiddenReleases = async () => {
      const { data } = await axios.get(
        `${process.env.NINA_API_ENDPOINT}/hubs/${hub.publicKey}/releases/archived`,
      )

      const { releases } = data
      setHiddenReleases(releases)
    }

    handleFetchHiddenReleases()
    handleFetchCollaborators()
  }, [date])

  const content: {
    general?: React.ReactNode
    collaborators?: React.ReactNode
    hiddenReleases?: React.ReactNode
  } = useMemo(
    () => ({
      general: isAuthority ? (
        <HubUpdateForm hub={hub} setIsOpen={setIsOpen} />
      ) : (
        <p className="body-1 mb-20 mt-32 text-grey-45 ">
          You do not have permission to edit this hub.
        </p>
      ),
      collaborators: (
        <>
          {!fetched && <HubCollaboratorSkeleton />}
          {fetched && (
            <HubCollaboratorSettings
              hub={hub}
              canAddCollaborator={canAddCollaborator}
              isAuthority={isAuthority}
              fetched={fetched}
              collaborators={hubCollaborators}
            />
          )}
        </>
      ),
      hiddenReleases: (
        <>
          {hiddenReleases && hiddenReleases.length > 0 ? (
            <ul>
              {hiddenReleases.map((release) => {
                return (
                  <HiddenListItem
                    release={release}
                    key={release.publicKey}
                    wallet={wallet}
                    addToast={addToast}
                    update={update}
                    hub={hub}
                  />
                )
              })}
            </ul>
          ) : (
            <p className="body-1 mb-20 mt-32 text-grey-45">
              This hub has no hidden releases.
            </p>
          )}
        </>
      ),
    }),
    [hiddenReleases, fetched],
  )

  return (
    <div className="mt-[20px] w-full ">
      <Tabs content={content} inSettings={true} />
    </div>
  )
}

export default memo(HubSettingsTabs)
