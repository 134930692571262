import Tabs from '@/components/Tabs'
import LoadingComponentStatic from '@/components/tokens/LoadingComponentStatic'

export default function HubInfoTabsSkeleton() {
  const content = {
    details: LoadingComponentStatic,
    collaborators: LoadingComponentStatic,
    followers: LoadingComponentStatic,
  }

  return (
    <div className="tabMarginTop">
      <Tabs content={content} />
    </div>
  )
}
