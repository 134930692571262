'use client'

import clsx from 'clsx'
import { useContext, useState } from 'react'
import { z } from 'zod'
import AccountListItem from '@/components/AccountListItem'
import Form from '@/components/Form'
import Button from '@/components/tokens/Button'
import Icon from '@/components/tokens/Icon'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { removeHubCollaborator } from '@/lib/hub/removeHubCollaborator'
import { updateHubCollaborator } from '@/lib/hub/updateHubCollaborator'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  FormField,
  Hub,
  HubCollaborator,
} from '@/lib/types'
import { validateFormSchema } from '@/lib/utils/validateFormSchema'

type HubCollaboratorSettingsForm = {
  forceOpen?: boolean
  collaborator: HubCollaborator
  index: number
  canAddCollaborator: boolean
  isAuthority: boolean
  hub: Hub
  expandedIndex: number | null
  toggleExpand: (index: number) => void
  setExpandedIndex: React.Dispatch<React.SetStateAction<number | null>>
}

export type HubCollaboratorData = {
  hubPublicKey: string
  collaboratorPublicKey: string
  canAddContent?: boolean
  canAddCollaborator?: boolean
  allowance?: number
  [key: string]: string | boolean | number | undefined
}

export default function HubCollaboratorSettingsForm(
  props: HubCollaboratorSettingsForm,
) {
  const {
    collaborator,
    index,
    canAddCollaborator,
    isAuthority,
    hub,
    expandedIndex,
    toggleExpand,
    setExpandedIndex,
  } = props

  const { accountData } = collaborator
  const { addToast } = useContext(Toast.Context)
  const { wallet } = useContext(Wallet.Context)
  const [submitting, setSubmitting] = useState(false)
  const { update } = useContext(Update.Context)

  const formatAllowance = (allowance: number) => {
    if (allowance === -1) return 'unlimited'

    return 'limited'
  }

  const hubCollaboratorFormFields: FormField[] = [
    {
      name: `canAddContent`,
      label: 'Can add content',
      type: 'checkbox',
      required: false,
      value: accountData?.collaborator?.canAddContent || false,
    },

    {
      name: `canAddCollaborator`,
      label: 'Can add collaborators',
      type: 'checkbox',
      required: false,
      value: accountData?.collaborator?.canAddCollaborator || false,
    },
    {
      name: `allowanceType`,
      label: '',
      type: 'conditional',
      required: false,
      value: formatAllowance(accountData?.collaborator?.allowance || 0),
      inputProps: {
        type: 'radio',
        dependentOn: `canAddCollaborator`,
        additionalDependency: `canAddContent`,
        dependencyTrigger: true,
        inputSelectOptions: [
          { label: 'Unlimited amount', value: 'unlimited' },
          { label: 'Limited amount', value: 'limited' },
        ],
      },
    },
    {
      name: `allowance`,
      label: '',
      type: 'conditional',
      placeholder: 'Enter an amount',
      hint: 'Amount determines the number of actions a Collaborator can execute on the Hub (i.e. publishing Releases through the Hub, reposting to the Hub and adding Collaborators to the Hub). Selecting ‘Unlimited’ will allow them unlimited actions. This can be updated at any time.',
      value: accountData?.collaborator?.allowance.toString() || -1,
      required: false,
      inputProps: {
        type: 'number',
        dependentOn: `allowanceType`,
        dependencyTrigger: 'limited',
      },
    },
  ]

  const [fields, setFields] = useState<FormField[]>(hubCollaboratorFormFields)
  const [isDisabled, setIsDisabled] = useState(true)

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    setFields((fields) => {
      const updatedFields = fields.map((field) => {
        const allowance = fields.find((field) => field.name === `allowance`)

        const allowanceType = fields.find(
          (field) => field.name === `allowanceType`,
        )

        if (field.name === `allowanceType`) {
          // type checking for allowance
          if (allowance) {
            if (value === 'limited') {
              // if the user selects 'limited', set the allowance to an empty string
              if (
                allowance.value === '-1' ||
                allowance.value === -1 ||
                allowance.value === undefined
              ) {
                allowance.value = ''
              }

              // if the user selects 'limited' after selecting unlimited, set the allowance to their current allowance if it exists
              if (
                accountData &&
                accountData.collaborator.allowance &&
                accountData.collaborator.allowance !== -1
              ) {
                allowance.value = accountData.collaborator.allowance.toString()
              }
            }

            // if the user selects 'unlimited', set the allowance to -1
            if (value === 'unlimited') {
              allowance.value = '-1'
            }
          }
        }

        // set the allowance to -1 if the user selects 'unlimited' for canAddContent or canAddCollaborators
        if (
          field.name === 'canAddContent' ||
          field.name === 'canAddCollaborators'
        ) {
          if (
            field.value === true &&
            allowance &&
            allowanceType?.value === 'unlimited'
          ) {
            allowance.value = '-1'
          }
        }

        if (field.name === name) {
          return {
            ...field,
            value: value,
          }
        }

        return field
      })

      const validationSchema = z.object({
        allowance: z.string().refine((data) => {
          return data !== ''
        }),
      })

      const validationResult = validateFormSchema(
        validationSchema,
        updatedFields,
        setFields,
      )

      if (validationResult.success) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }

      return updatedFields
    })
  }

  const formatData = () => {
    const data: HubCollaboratorData = {
      hubPublicKey: hub.publicKey,
      collaboratorPublicKey: collaborator.publicKey,
      canAddContent: undefined,
      canAddCollaborator: undefined,
      allowance: accountData?.collaborator?.allowance || -1,
    }

    fields.forEach((field: FormField) => {
      data[field.name] = field.value

      if (field.name === 'collaboratorPublicKey') {
        data['collaboratorPublicKey'] = field.value.replace(/\s/g, '')
      }

      if (field.name === `allowance`) {
        data['allowance'] = parseInt(field.value) || -1
      }

      if (field.name === `canAddContent`) {
        data['canAddContent'] = field.value
      }

      if (field.name === `canAddCollaborator`) {
        data['canAddCollaborator'] = field.value
      }
    })

    return data
  }

  const handleUpdateCollaborator = async (
    e: React.FormEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitting(true)

    try {
      const data = formatData()

      const {
        hubPublicKey,
        collaboratorPublicKey,
        canAddContent,
        canAddCollaborator,
        allowance,
      } = data

      const response = await updateHubCollaborator({
        hubPublicKey,
        collaboratorPublicKey,
        canAddContent: canAddContent || false,
        canAddCollaborator: canAddCollaborator || false,
        allowance: allowance || -1,
        wallet,
      })

      addToast({
        message: `Updating collaborator permissions for ${collaborator.displayName}`,
        variant: 'loading',
      })

      if (response.error) {
        addToast({
          message: response.error,
          variant: 'error',
        })
      } else {
        addToast({
          message: `Collaborator permissions for ${collaborator.displayName} updated successfully`,
          variant: 'success',
        })
        update()
        setExpandedIndex(null)
      }
    } catch (error) {
      addToast({
        message: 'Hub collaborator permissions could not be updated',
        variant: 'error',
      })
    }
    setSubmitting(false)

    return
  }

  const handleRemoveCollaborator = async (
    e: React.FormEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitting(true)

    try {
      const response = await removeHubCollaborator({
        hubPublicKey: hub.publicKey,
        collaboratorPublicKey: collaborator.publicKey,
        wallet,
      })

      addToast({
        message: `Removing ${collaborator.displayName} from hub`,
        variant: 'loading',
      })

      if (response?.error) {
        addToast({
          message: response.error,
          variant: 'error',
        })
      } else {
        addToast({
          message: `${collaborator.displayName} removed successfully`,
          variant: 'success',
        })
        update()
        setExpandedIndex(null)
      }
    } catch (error) {
      addToast({
        message: 'Hub collaborator could not be removed',
        variant: 'error',
      })
    }
    setSubmitting(false)

    return
  }

  return (
    <li className="border-b-1 border-grey-10 last-of-type:border-none">
      <div className="relative flex w-full items-center justify-between sm:mr-28">
        <div
          className={clsx(
            'flex items-center',
            submitting && 'pointer-events-none',
          )}
        >
          <AccountListItem
            item={collaborator}
            type={'collaborators'}
            disableLink={true}
          />
        </div>

        <div className="flex items-center">
          {index === 0 && (
            <p className="ui-1 text-grey-45">Is the admin of this hub</p>
          )}

          {index > 0 && isAuthority && (
            <button
              className={clsx(
                'ui-1 flex text-grey-45 ',
                !submitting && 'hover:opacity-80',
                submitting && 'pointer-events-none',
              )}
              onClick={() => toggleExpand(index)}
              disabled={submitting}
            >
              Permissions
              {expandedIndex === index && (
                <Icon
                  name="chevronUpLight"
                  className="grey-45 ml-4"
                  height={16}
                  width={16}
                />
              )}{' '}
              {expandedIndex !== index && (
                <Icon
                  name="chevronDownLight"
                  className="grey-45 ml-4"
                  height={16}
                  width={16}
                />
              )}
            </button>
          )}
        </div>
      </div>
      {canAddCollaborator && expandedIndex === index && (
        <div className={'w-full'}>
          <div
            className={clsx(
              'mb-[12px] mt-[28px]',
              submitting && 'pointer-events-none opacity-20',
            )}
          >
            <Form
              fields={fields}
              setFields={setFields}
              buttonLabel="Update Collaborator"
              handleChange={handleChange}
              handleSubmit={(e) => handleUpdateCollaborator(e)}
              isDisabled={isDisabled || submitting}
            />
          </div>
          <Button
            color={ButtonColor.Warning}
            variant={ButtonVariant.Large}
            font={ButtonFont.Body1}
            label="Remove Collaborator"
            responsiveness={ButtonResponsiveness.Full}
            onClick={(e) => handleRemoveCollaborator(e)}
            disabled={submitting}
          />
        </div>
      )}
    </li>
  )
}
