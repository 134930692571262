import clsx from 'clsx'
import { View } from '@/lib/types'

export default function ContentPageSkeleton({
  items = 5,
  shouldTruncate = false,
  view,
}: {
  items?: number
  shouldTruncate?: boolean
  view: View
}) {
  const renderGrid = () => {
    const rowsArray = []
    for (let i = 0; i < items; i++) {
      rowsArray.push(
        <div key={i} className="h-full ">
          <div className="max-h-[353px] min-h-[160px] w-full">
            <div className="aspect-h-1 aspect-w-1 max-h-[353px] min-h-[160px]">
              <div className="shimmer box-border w-full bg-blue-5" />
            </div>
          </div>
          <div className="shimmer body-1 mt-12 h-[40px] w-full bg-blue-5 pb-8" />
        </div>,
      )
    }

    return rowsArray
  }

  const renderList = () => {
    const rowsArray = []
    for (let i = 0; i < items; i++) {
      rowsArray.push(
        <li
          key={i}
          className="body-1 shimmer flex h-[64px] w-full justify-between border-t-1 border-grey-10 bg-blue-5 py-12"
        />,
      )
    }

    return rowsArray
  }

  if (view === View.Grid) {
    return (
      <div className="grid">
        <div className="block">
          <div
            className={clsx(
              shouldTruncate && 'truncatedGrid-max-5',
              'grid grid-cols-2 gap-x-12 gap-y-20 sm:gap-y-28 md:grid-cols-4 md:gap-x-20 lg:gap-x-28 xl:grid-cols-5',
            )}
          >
            {renderGrid()}
          </div>
        </div>
      </div>
    )
  } else {
    return <ul className="[&>*:first-child]:border-none">{renderList()}</ul>
  }
}
