'use client'

import parse from 'html-react-parser'
import { useCallback, useContext, useEffect, useState } from 'react'
import Update from '@/contexts/UpdateContext'
import { fetchHub } from '@/lib/hub/fetchHub'
import { Hub } from '@/lib/types'

type HubDetailsProps = {
  hub: Hub
}

export default function HubDetails({ hub: hubData }: HubDetailsProps) {
  const { date } = useContext(Update.Context)
  const [initialLoad, setInitialLoad] = useState(true)
  const [hub, setHub] = useState<Hub>(hubData || ({} as Hub))

  const handleFetchHub = useCallback(async () => {
    if (initialLoad) {
      setInitialLoad(false)

      return
    }

    try {
      const { hub: fetchedHubData } = await fetchHub(hub.handle)

      if (fetchedHubData) {
        setHub(fetchedHubData)
      }
    } catch (error) {
      console.error('Error fetching hub:', error)
    }
  }, [date])

  useEffect(() => {
    handleFetchHub()
  }, [handleFetchHub])

  const description = hub.data?.description
  const descriptionHtml = hub.data?.descriptionHtml || ''

  const sanitizedDescription = descriptionHtml
    .replace(/^"/, '')
    .replace(/"$/, '')

  const parsedHtml = parse(sanitizedDescription)

  if (
    (descriptionHtml === '' && description === '') ||
    description === 'undefined'
  )
    return <p className="body-1 text-grey-45">This hub has no description.</p>

  return (
    <div className="h-auto w-full md:w-1/2">
      {descriptionHtml && (
        <div className="body-1 whitespace-pre-wrap py-8">{parsedHtml}</div>
      )}
      {description && !descriptionHtml && (
        <div className="body-1 whitespace-pre-wrap py-8">{description}</div>
      )}
    </div>
  )
}
