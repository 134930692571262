import Nina from '@nina-protocol/js-sdk'
import { WalletContextState } from '@solana/wallet-adapter-react'
import axios from 'axios'
import initSdk from '@/lib/utils/initSdk'
import { TxResponse } from '../types'

type AddCollaboratorToHubProps = {
  hubPublicKey: string
  collaboratorPublicKey: string
  canAddContent: boolean
  canAddCollaborators: boolean
  allowance: number
  wallet: WalletContextState
}

// TODO: fix implementation
export const addColloboratorToHub = async ({
  hubPublicKey,
  collaboratorPublicKey,
  canAddContent,
  canAddCollaborators,
  allowance,
  wallet,
}: AddCollaboratorToHubProps) => {
  try {
    await initSdk(wallet)

    const response = await Nina.Hub.hubAddCollaborator(
      hubPublicKey,
      collaboratorPublicKey,
      canAddContent,
      canAddCollaborators,
      allowance,
      true,
    )

    const txResponse: TxResponse = await axios.post(
      `${process.env.NINA_FILE_SERVICE_ENDPOINT}/tx`,
      {
        tx: response.tx,
        hubPublicKey: response.hubPublicKey,
        hubCollaboratorPublicKey: response.collaboratorPublicKey,
        type: response.type,
      },
    )

    return txResponse.data
  } catch (error) {
    console.error(error)

    return {
      error: `Error adding collaborator to hub: ${error}`,
    }
  }
}
