'use client'

import { useCallback, useContext, useEffect, useState } from 'react'
import AccountListItem from '@/components/AccountListItem'
import Update from '@/contexts/UpdateContext'
import { fetchCollaboratorsForHub } from '@/lib/account/fetchCollaboratorsForHub'
import { Hub, HubCollaborator } from '@/lib/types'
import { HeaderTotals } from '@/lib/types'

type HubCollaboratorProps = {
  hub: Hub
  limit: number
  setHeaderTotals?: React.Dispatch<React.SetStateAction<HeaderTotals>>
}

export default function HubCollaborators({
  hub,
  limit,
  setHeaderTotals,
}: HubCollaboratorProps) {
  const [collaborators, setCollaborators] = useState([])
  const { date } = useContext(Update.Context)

  const handleFetchCollaborators = useCallback(async () => {
    try {
      const response = await fetchCollaboratorsForHub(
        hub.handle,
        {
          limit,
          offset: 0,
          sort: 'desc',
        },
        true,
      )

      setCollaborators(response.collaborators)

      if (setHeaderTotals) {
        setHeaderTotals((prevState: HeaderTotals) => {
          return {
            ...prevState,
            collaborators: response.total,
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }, [date, hub])

  useEffect(() => {
    handleFetchCollaborators()
  }, [handleFetchCollaborators])

  if (!collaborators || collaborators.length === 0) return null

  return (
    <ul>
      {collaborators.map((collaborator: HubCollaborator, index: number) => {
        return (
          <li
            className="w-full border-b-1 border-grey-10 last-of-type:border-none sm:mr-28 sm:w-1/2"
            key={index}
          >
            <AccountListItem item={collaborator} type={'collaborators'} />
          </li>
        )
      })}
    </ul>
  )
}
