'use client'

import { memo, useContext, useEffect, useMemo, useState } from 'react'
import Follow from '@/components/Follow'
import ImageWithArweaveFallback from '@/components/ImageWithArweaveFallback'
import Button from '@/components/tokens/Button'
import Audio from '@/contexts/AudioContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { fetchHub } from '@/lib/hub/fetchHub'
import {
  ButtonColor,
  ButtonVariant,
  Hub,
  HubCollaborator,
  PlayType,
  Release,
} from '@/lib/types'
import ImagePlaceholderBase64 from '@/lib/utils/ImagePlaceholderBase64'
import HubHeaderListBox from './HubHeaderListBox'
import HubSettingsModal from './HubSettingsModal'

type HubHeaderProps = {
  hub: Hub
  releases: Release[]
  collaborators: HubCollaborator[]
}

function HubHeader(props: HubHeaderProps) {
  const {
    hub: hubData,
    releases: hubReleases,
    collaborators: hubCollaboratorsData,
  } = props

  const { date } = useContext(Update.Context)
  const { addMultipleReleasesToQueue } = useContext(Audio.Context)
  const { userHubs, wallet } = useContext(Wallet.Context)
  const [hub, setHub] = useState<Hub>(hubData || ({} as Hub))
  const [initialLoad, setInitialLoad] = useState(true)
  const [releases, setReleases] = useState<Release[]>(hubReleases || [])

  const [collaborators, setCollaborators] = useState<HubCollaborator[]>(
    hubCollaboratorsData || [],
  )

  const [canAddCollaborator, setCanAddCollaborator] = useState(false)
  const [isAuthority, setIsAuthority] = useState(false)
  useEffect(() => {
    const handleFetchHub = async () => {
      if (initialLoad) {
        setInitialLoad(false)

        return
      }

      try {
        const { hub: fetchedHubData, collaborators } = await fetchHub(
          hub.handle,
        )

        if (fetchedHubData) {
          setHub(fetchedHubData)
        }

        if (collaborators) {
          setCollaborators(collaborators)
        }
      } catch (error) {
        console.warn(error)
      }
    }

    handleFetchHub()
  }, [date])

  useEffect(() => {
    const handleFetchHubReleases = async () => {
      if (initialLoad) {
        setInitialLoad(false)

        return
      }

      try {
        const { releases: fetchedReleases } = await fetchHub(hub.handle)

        if (fetchedReleases) {
          setReleases(fetchedReleases)
        }
      } catch (error) {
        console.warn(error)
      }
    }

    handleFetchHubReleases()
  }, [date])

  const isUserHub = useMemo(
    () => userHubs.some((userHub) => userHub.publicKey === hub.publicKey),
    [userHubs, hub],
  )

  useEffect(() => {
    const checkCollaboratorPermissions = () => {
      if (!wallet.publicKey) return

      const hasPermission = collaborators.some(
        (collaborator) =>
          collaborator.accountData?.collaborator?.collaborator ===
            wallet.publicKey?.toBase58() &&
          collaborator.accountData?.collaborator?.canAddCollaborator,
      )

      setCanAddCollaborator(hasPermission)
    }

    checkCollaboratorPermissions()
  }, [collaborators, wallet.publicKey, date])

  useEffect(() => {
    const checkIfAuthority = () => {
      if (!wallet.publicKey) return

      setIsAuthority(wallet.publicKey?.toBase58() === hub.authority)
    }

    checkIfAuthority()
  }, [date, collaborators, wallet.publicKey])

  const handlePlayHub = (playType: PlayType) => {
    addMultipleReleasesToQueue(releases, playType)
  }

  const noImage =
    hub?.data?.image === 'https://arweave.net/' ||
    hub?.data?.image === 'https://arweave.net/undefined' ||
    hub?.data?.image === '' ||
    !hub?.data?.image

  return (
    <div className="flex flex-col md:flex-row ">
      {noImage && (
        <div className="imageBorder max-h-[160px] min-h-[160px]  min-w-[160px] max-w-[160px]" />
      )}
      {!noImage && (
        <ImageWithArweaveFallback
          src={hub.data?.image}
          alt={hub?.handle}
          width={160}
          height={160}
          className="imageBorder h-[160px]  w-[160px] object-cover"
          priority={true}
          placeholder="blur"
          blurDataURL={ImagePlaceholderBase64}
        />
      )}
      <div className="mt-16 flex w-full justify-between md:ml-20 md:mt-0 lg:ml-28">
        <div className="flex flex-col items-start ">
          <h1 className="display-1 min-h-[32px] text-blue-82">
            {hub.data?.displayName}
          </h1>
          <div className="my-20 flex items-center space-x-12">
            <abbr title={'Play hub'} className="no-underline">
              <Button
                icon="playSmall"
                variant={ButtonVariant.Square}
                color={ButtonColor.Solid}
                onClick={() => handlePlayHub(PlayType.Now)}
              />
            </abbr>
            <abbr title={'Queue hub'} className="no-underline">
              <div className="md:hover:opacity-70">
                <HubHeaderListBox hub={hub} releases={releases} />
              </div>
            </abbr>
          </div>

          <div className="flex flex-row gap-x-16">
            <Follow
              publicKey={hub.publicKey}
              hubHandle={hub.handle}
              displayName={hub?.data?.displayName}
            />
            {/* <ShareButton pathname={`/hubs/${hub.handle}`} type="hub url" /> */}

            {isUserHub && wallet.publicKey && hub && (
              <HubSettingsModal
                hub={hub}
                collaborators={collaborators}
                isAuthority={isAuthority}
                canAddCollaborator={canAddCollaborator}
                releases={releases}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(HubHeader)
