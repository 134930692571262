'use client'

import React, { Suspense, memo, useState } from 'react'
import Modal from '@/components/Modal'
import {
  ButtonColor,
  ButtonFont,
  ButtonVariant,
  Hub,
  HubCollaborator,
  ModalType,
  Release,
} from '@/lib/types'
import HubSettingsTabs from '../HubSettingsTabs'

type HubSettingsModal = {
  hub: Hub
  collaborators: HubCollaborator[]
  isAuthority: boolean
  canAddCollaborator: boolean
  inSettings?: boolean
  releases: Release[] | undefined
}

function HubSettingsModal(props: HubSettingsModal) {
  const { hub, collaborators, isAuthority, canAddCollaborator, releases } =
    props

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalHeader={'Hub Settings'}
      modalLabelVariant={ButtonVariant.Unstyled}
      modalLabelColor={ButtonColor.Unstyled}
      modalLabelFont={ButtonFont.UI1}
      modalLabelTextColor={ButtonColor.Black}
      modalType={ModalType.Default}
      label={'Settings'}
      disableKeydownSubmit={true}
    >
      <Suspense fallback={<div className="ui-1 pb-28">Loading...</div>}>
        <HubSettingsTabs
          hub={hub}
          collaborators={collaborators}
          isAuthority={isAuthority}
          canAddCollaborator={canAddCollaborator}
          inSettings={true}
          setIsOpen={setIsOpen}
          releases={releases}
        />
      </Suspense>
    </Modal>
  )
}

export default memo(HubSettingsModal)
