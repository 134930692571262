import Nina from '@nina-protocol/js-sdk'
import { WalletContextState } from '@solana/wallet-adapter-react'
import axios from 'axios'
import initSdk from '@/lib/utils/initSdk'
import { TxResponse } from '../types'

type RemoveHubCollaboratorProps = {
  hubPublicKey: string
  collaboratorPublicKey: string
  wallet: WalletContextState
}

export const removeHubCollaborator = async ({
  hubPublicKey,
  collaboratorPublicKey,
  wallet,
}: RemoveHubCollaboratorProps) => {
  try {
    await initSdk(wallet)

    const tx = await Nina.Hub.hubRemoveCollaborator(
      hubPublicKey,
      collaboratorPublicKey,
      true,
    )

    const txResponse: TxResponse = await axios.post(
      `${process.env.NINA_FILE_SERVICE_ENDPOINT}/tx`,
      {
        tx,
      },
    )

    return txResponse.data
  } catch (error) {
    console.error(error)

    return {
      error: `Error removing hub collaborator: ${error}`,
    }
  }
}
