import React from 'react'

export default function HubCollaboratorSkeleton() {
  const renderRows = () => {
    const rowsArray = []
    const rows = 3
    for (let i = 0; i < rows; i++) {
      rowsArray.push(
        <li
          key={i}
          className="shimmer flex h-[64px] w-full flex-row justify-between bg-grey-3 px-12 py-10"
        />,
      )
    }

    return rowsArray
  }

  return <ul className="[&>*:first-child]:border-none">{renderRows()}</ul>
}
