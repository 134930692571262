import Nina from '@nina-protocol/js-sdk'
import { WalletContextState } from '@solana/wallet-adapter-react'
import axios from 'axios'
import initSdk from '@/lib/utils/initSdk'
import { TxResponse } from '../types'

type updateHubCollaboratorProps = {
  hubPublicKey: string
  collaboratorPublicKey: string
  canAddContent: boolean
  canAddCollaborator: boolean
  allowance: number
  wallet: WalletContextState
}

export const updateHubCollaborator = async ({
  hubPublicKey,
  collaboratorPublicKey,
  canAddContent,
  canAddCollaborator,
  allowance,
  wallet,
}: updateHubCollaboratorProps) => {
  try {
    await initSdk(wallet)

    const tx = await Nina.Hub.hubUpdateCollaboratorPermission(
      hubPublicKey,
      collaboratorPublicKey,
      canAddContent,
      canAddCollaborator,
      allowance,
      true,
    )

    const txResponse: TxResponse = await axios.post(
      `${process.env.NINA_FILE_SERVICE_ENDPOINT}/tx`,
      {
        tx,
      },
    )

    return txResponse.data
  } catch (error) {
    console.error(error)

    return {
      error: `Error updating hub collaborator: ${error}`,
    }
  }
}
