'use client'

import { useState } from 'react'
import { Hub, HubCollaborator } from '@/lib/types'
import HubAddCollaboratorForm from './HubAddCollaboratorForm'
import HubCollaboratorSettingsForm from './HubCollaboratorSettingsForm'

type HubCollaboratorSettingsProps = {
  collaborators?: HubCollaborator[]
  hub: Hub
  canAddCollaborator: boolean
  isAuthority: boolean
  fetched: boolean
}

export default function HubCollaboratorSettings(
  props: HubCollaboratorSettingsProps,
) {
  const { hub, canAddCollaborator, isAuthority, collaborators } = props
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

  const [isAddCollaboratorFormOpen, setIsAddCollaboratorFormOpen] =
    useState<boolean>(false)

  const toggleExpand = (index: number | undefined) => {
    if (index === undefined) {
      setExpandedIndex(null)
      setIsAddCollaboratorFormOpen(true)
    } else {
      setExpandedIndex(index === expandedIndex ? null : index)
      setIsAddCollaboratorFormOpen(false)
    }
  }

  if (!collaborators || collaborators.length === 0) return null

  return (
    <div className="no-scrollbar mb-20 mt-[32px] max-h-[calc(100dvh_-_375px)] w-full overflow-y-scroll">
      <ul className="mb-28 w-full">
        {collaborators.map((collaborator: HubCollaborator, index: number) => {
          return (
            <HubCollaboratorSettingsForm
              collaborator={collaborator}
              hub={hub}
              key={index}
              index={index}
              canAddCollaborator={canAddCollaborator}
              isAuthority={isAuthority}
              expandedIndex={expandedIndex}
              toggleExpand={() => toggleExpand(index)}
              setExpandedIndex={setExpandedIndex}
            />
          )
        })}
      </ul>
      {canAddCollaborator && (
        <HubAddCollaboratorForm
          hub={hub}
          expanded={isAddCollaboratorFormOpen}
          setExpanded={setIsAddCollaboratorFormOpen}
          collaborators={collaborators}
          toggleExpand={() => toggleExpand(undefined)}
        />
      )}
    </div>
  )
}
